import Rest from '@/services/Rest';

/**
 * @typedef {UpsellService}
 */
export default class UpsellService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/upsell'
}
