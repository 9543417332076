<template>
  <div class="container">
    <BaseHeader :title="$t('views.checkouts.text_957')"></BaseHeader>

    <b-tabs
      class="mt-4"
      nav-class="TabHeader no-border"
      :active-nav-item-class="isHeaven ? 'TabHeader-heaven--active' : 'TabHeader--active'"
      :no-nav-style="true"
      v-model="tabIndex"
    >
      <b-tab :title="$t('views.checkouts.text_958')">
        <BaseCheckout />
      </b-tab>
      <b-tab title="Upsell">
        <BaseUpsell2 />
      </b-tab>
    </b-tabs>
    <CallEvaluation/>
  </div>
</template>
<script>
import moment from "moment-timezone";
import BaseCheckout from "@/components/Seller/Checkout/base";
import BaseUpsell from "@/components/Seller/Upsell/base";
import BaseUpsell2 from "@/components/Seller/Upsell/base2";
import Cookies from "js-cookie";

export default {
  components: {
    BaseCheckout,
    BaseUpsell,
    BaseUpsell2
  },
  data() {
    return {
      submit: false,
      loading: false,
      config: false,
      configUpsell:false,
      edit: null,
      client: {
        width: 0,
      },
      tabIndex:0,
      maxLength: 48,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      subUserCustomCheckoutAccess: false
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    baseAdmURL() {
      return this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE;
    },
    username() {
      return this.$store.getters.getProfile.username;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.maxLength = 33;
      } else this.maxLength = 48;
    },
  },
  methods: {
    getPermissions() {
      if (this.$store.getters.setPanel) {
        let dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (dados.find((x) => x.name === "CustomCheckoutRead")) {
            this.subUserCustomCheckoutAccess = true;
          }
        }
      }
    },
    changeTabs() {
      const urlParams = new URLSearchParams(window.location.search);
      const upsell = urlParams.get("upsell");
      if (upsell == 'true') {
       this.tabIndex = 1;
      }
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
  },
  created() {
    this.getPermissions()
    if(this.$store.getters.setPanel && !this.subUserCustomCheckoutAccess){
      this.$router.push("/dashboard");
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.changeTabs();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
</style>