import Rest from '@/services/Rest';

/**
 * @typedef {UpsellMetaService}
 */
export default class UpsellMetaService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/upsell/meta'
}
