<template>
  <div fluid class="body">
    <div class="d-flex justify-content-end">
      <BaseButton
        variant="primary"
        @click="makeUpsell"
        v-if="!isMobile && !config"
        id="criar_upsell"
        >Criar Upsell</BaseButton
      >
    </div>

    <div v-if="!config" class="mt-4">
      <b-row class="mb-4" v-if="upsells.length >= 1 && !isMobile">
        <b-col cols="12" md="5" class="sub-titulo"> Nome do Upsell </b-col>
        <b-col cols="12" md="6" class="sub-titulo"> Código </b-col>
      </b-row>
      <template v-for="item in upsells">
        <b-row :key="item.id" class="link-extra mb-4" v-if="item.new_upsell === false">
          <b-col cols="12" md="5" class="titulo-link">
            {{ item.title }}
          </b-col>
          <b-col cols="12" md="6">
            <a class="link">{{ codeUp(item) }}</a>
          </b-col>
          <b-col cols="12" md="1" class="white-space">
            <span @click="copyAddress(codeUp(item))" class="btn p-2">
              <img src="@/assets/img/icons/copy.svg" class="copy-link" />
            </span>
            <span @click="editMeta(item)" class="btn p-2">
              <img src="@/assets/img/icons/editar.svg" class="copy-link" />
            </span>
            <span @click="deleteUpsell(item.id)" class="btn p-2">
              <img src="@/assets/img/icons/lixeira.svg" class="copy-link" />
            </span>
          </b-col>
        </b-row>
      </template>
    </div>

    <b-row
      key="nao-encontrado"
      v-if="!loading && upsells.length == 0 && !config"
      class="justify-content-center"
    >
      <p class="nao-encontrado">Nenhum upsell foi encontrado</p>
    </b-row>

    <div v-if="!loading && config" data-anima="top">
      <div class="gridGerador">
        <span>
          <b-col cols="12" md="10" class="mt-5">
            <b-form-group
              label="Nome do Upsell"
              label-for="url"
              data-anima="top"
            >
              <b-form-input
                v-model="upsellName"
                placeholder="Nome do Upsell"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Selecione o Produto"
              label-for="url"
              data-anima="top"
              class="mt-2"
            >
              <b-form-select
                v-model="product_id"
                :options="products"
                @change="changeProduct"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-form-group
            label="Configure o Botão"
            label-for="url"
            data-anima="top"
            class="all-config"
          >
            <span>
              <div
                class="config"
                @click="toggleTab('config')"
                v-if="type !== 'config'"
              >
                <b-form-group
                  label="Fonte e Texto"
                  label-for="url"
                  data-anima="top"
                  class="pointer-label"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>

              <div class="config active-lab" v-if="type === 'config'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      label="Fonte e Texto"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <b-form-group
                    label="Texto do Botão"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="textBtn"
                      placeholder="Meu Botão"
                    ></b-form-input>
                  </b-form-group>
                  <div class="top-label"></div>
                  <b-form-group
                    :label="'Tamanho do Texto: ' + fontSize + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="fontSize"
                      type="range"
                      min="5"
                      max="50"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Estilo do texto"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="typeText"
                      name="flavour-2"
                      @change="activeStyle()"
                    >
                      <b-form-checkbox value="bold" class="bold check"
                        >Bold</b-form-checkbox
                      >
                      <b-form-checkbox value="italic" class="italic"
                        >Itálico</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                  <div class="dimension">
                    <b-form-group
                      :label="'Altura: ' + height + 'px'"
                      label-for="url"
                      data-anima="top"
                    >
                      <b-form-input
                        v-model="height"
                        type="range"
                        min="10"
                        max="100"
                        class="not-border"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      :label="'Largura: ' + width + medida"
                      label-for="url"
                      data-anima="top"
                    >
                      <b-input-group class="mt-3">
                        <b-form-input
                          type="number"
                          v-model="width"
                          placeholder="100"
                          @focus="focusInput"
                          class="ajustBorder"
                        >
                        </b-form-input>
                        <b-input-group-append>
                          <b-form-select
                            @change="changeWidth"
                            v-model="medida"
                            class="ajustBorder2"
                            :options="medidas"
                          ></b-form-select>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>

                    <b-form-input
                      v-if="medida === 'px' && mostRange"
                      v-model="width"
                      type="range"
                      min="5"
                      max="500"
                      class="not-border"
                    ></b-form-input>
                    <b-form-input
                      v-if="medida === '%' && mostRange"
                      v-model="width"
                      type="range"
                      min="0"
                      max="100"
                      class="not-border"
                    ></b-form-input>
                  </div>
                </span>
              </div>
            </span>

            <span>
              <div
                class="config"
                @click="toggleTab('color')"
                v-if="type !== 'color'"
              >
                <b-form-group
                  label="Cores"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>

            <span>
              <div class="config active-lab" v-if="type === 'color'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      label="Cores"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <b-form-group
                    :label="'Cor do Fundo: ' + colorBtn"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorBtn"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <b-form-group
                    :label="'Cor do Texto: ' + colorTxt"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorTxt"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                </span>
              </div>
            </span>

            <span>
              <div
                class="config"
                @click="toggleTab('borda')"
                v-if="type !== 'borda'"
              >
                <b-form-group
                  label="Bordas"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>

            <span>
              <div class="config active-lab" v-if="type === 'borda'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      label="Bordas"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <b-form-group
                    :label="'Cor da Borda: ' + colorBorder"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorBorder"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <b-form-group
                    :label="'Borda: ' + bordaWeight + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="bordaWeight"
                      type="range"
                      min="0"
                      max="20"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    :label="'Borda Arredondada: ' + bordaRadius + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="bordaRadius"
                      type="range"
                      min="0"
                      max="100"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>
                </span>
              </div>
            </span>

            <span>
              <div
                class="config"
                @click="toggleTab('notbuy')"
                v-if="type !== 'notbuy'"
              >
                <b-form-group
                  label="Link de não comprar"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>

            <span>
              <div class="config active-lab" v-if="type === 'notbuy'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      label="Link de não comprar"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>

                  <b-form-group
                    label=""
                    label-for="url"
                    data-anima="top"
                    class="ml-switch"
                  >
                    <b-form-checkbox
                      v-model="checkedNotBuy"
                      name="check-button"
                      switch
                    >
                      Opção de Não Comprar
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    class="mt-2"
                    label="Texto do Link"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="textBtnLink"
                      placeholder="Link"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    label="Link de Redirecionamento"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="linkToNotBuy"
                      placeholder="Link de redirecionamento"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="'Cor do Texto: ' + colorTxtLink"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorTxtLink"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <div class="top-label"></div>
                  <b-form-group
                    :label="'Tamanho do Texto: ' + fontSizeLink + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="fontSizeLink"
                      type="range"
                      min="5"
                      max="50"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Estilo do texto"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="typeTextLink"
                      name="flavour-2"
                      @change="activeStyleLink()"
                    >
                      <b-form-checkbox value="bold" class="bold check"
                        >Bold</b-form-checkbox
                      >
                      <b-form-checkbox value="italic" class="italic"
                        >Itálico</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                </span>
              </div>
            </span>

            <span>
              <div
                class="config"
                @click="toggleTab('hover')"
                v-if="type !== 'hover'"
              >
                <b-form-group
                  label="Ao passar o mouse"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>

            <span>
              <div class="config active-lab" v-if="type === 'hover'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      label="Ao passar o mouse"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <b-form-group
                    :label="'Cor de Fundo: ' + colorHover"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorHover"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <b-form-group
                    :label="'Cor da Borda: ' + colorBorderHover"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorBorderHover"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <b-form-group
                    :label="'Borda: ' + bordaWeightHover + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="bordaWeightHover"
                      type="range"
                      min="0"
                      max="20"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Animação">
                    <b-form-radio-group
                      id="btn-radios-2"
                      v-model="selectedAnime"
                      :options="optionsAnime"
                      button-variant="outline-primary"
                      class="btn-entrega button-info3 btn-anime"
                      size="lg"
                      name="radio-btn-outline"
                      buttons
                    ></b-form-radio-group>
                  </b-form-group>
                </span>
              </div>
            </span>

            <span>
              <div
                class="config"
                @click="toggleTab('pgn-obrigado')"
                v-if="type !== 'pgn-obrigado'"
              >
                <b-form-group
                  label="Página de Obrigado"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>

            <span>
              <div class="config active-lab" v-if="type === 'pgn-obrigado'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      label="Página de Obrigado"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>

                  <b-form-group
                    class="mt-2"
                    label="Link da Página de Obrigado(Opcional)"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="pgnLink"
                      placeholder="Link da Página de Obrigado"
                    ></b-form-input>
                  </b-form-group>
                </span>
              </div>
            </span>
          </b-form-group>
          <div class="advanced">
            <p class="btn-p" @click="advanced = !advanced">Avançado</p>
            <span>
              <div class="config" v-if="advanced" data-anima="top">
                <b-form-group
                  label="UpSell não injete o botão na pagina"
                  class="toggle-label pointer-label"
                  label-for="url"
                >
                  <b-form-checkbox
                    size="md"
                    switch
                    v-model="checkedInject"
                    class="check-box mb-2 ml-custom"
                    name="check"
                  >
                    <p class="info-checkbox">Utilizar meu próprio Botão</p>
                  </b-form-checkbox>
                </b-form-group>
                <p class="mb-2">
                  Ao ativar essa funcionalidade, sera liberado 3 funções para
                  uso do Upsell:
                </p>
                <p class="mb-1">
                  <b>greennUpSell </b> -> Realiza o UpSell utilizando o mesmo
                  método de pagamento
                </p>
                <p class="mb-1">
                  <b>greennUpSellChangeMethod </b> -> Realiza o UpSell porém o
                  usuário escolhe outro método de pagamento
                </p>
                <p class="mb-2">
                  <b>greennUpSellDeny </b> -> Não realiza o UpSell, redireciona
                  para pagina configurada no UpSell
                </p>
                <p class="mb-2">
                  <b>Modo de Uso </b> -> window.greennFunctionPayment();
                </p>
              </div>
            </span>
          </div>
        </span>
        <span>
          <div class="preview-btn d-flex justify-content-center">
            <div id="info">
              <div>
                <p id="product-title">
                  {{ nomeProduto }} - R$ {{ formatMoney(valorProduto) }}
                </p>
              </div>

              <div class="body">
                <div id="payment-inputs">
                  <div class="boleto">
                    <div class="custom-input">
                      <svg
                        class="icon"
                        width="29"
                        height="22"
                        viewBox="0 0 29 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.5708 0.840088H18.3708V21.8401H15.5708V0.840088ZM4.37078 0.840088V21.8401H1.57078C0.797578 21.8401 0.170776 21.2133 0.170776 20.4401V2.24009C0.170776 1.46689 0.797578 0.840088 1.57078 0.840088H4.37078ZM19.7708 0.840088H22.5708V21.8401H19.7708V0.840088ZM25.3708 0.840088H26.7708C27.544 0.840088 28.1708 1.46689 28.1708 2.24009V20.4401C28.1708 21.2133 27.544 21.8401 26.7708 21.8401H25.3708V0.840088Z"
                          fill="#8C8A97"
                        />
                        <path
                          opacity="0.3"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.97078 0.840088V21.8401H7.17078V0.840088H9.97078Z"
                          fill="#8C8A97"
                        />
                      </svg>
                      <input
                        class="boleto-preview"
                        value="Realizar pagamento com Boleto"
                      />
                      <svg
                        class="icon-ok"
                        width="25"
                        height="25"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.9873 0.840088C3.85185 0.840088 0.487305 4.20463 0.487305 8.34009C0.487305 12.4755 3.85185 15.8401 7.9873 15.8401C12.1228 15.8401 15.4873 12.4755 15.4873 8.34009C15.4873 4.20463 12.1228 0.840088 7.9873 0.840088ZM11.8905 5.82651L7.0444 11.5957C6.99124 11.6591 6.92509 11.7102 6.85044 11.7457C6.77578 11.7813 6.69437 11.8003 6.6117 11.8016H6.60197C6.5211 11.8016 6.44115 11.7846 6.36729 11.7516C6.29343 11.7187 6.22732 11.6706 6.17324 11.6105L4.09632 9.30283C4.04357 9.24688 4.00254 9.18096 3.97564 9.10893C3.94873 9.0369 3.9365 8.96022 3.93965 8.8834C3.9428 8.80657 3.96128 8.73115 3.994 8.66157C4.02672 8.59199 4.07301 8.52965 4.13017 8.47822C4.18732 8.42678 4.25418 8.38729 4.32681 8.36207C4.39945 8.33684 4.47639 8.32639 4.55312 8.33132C4.62985 8.33626 4.70482 8.35648 4.77363 8.39081C4.84243 8.42513 4.90368 8.47286 4.95377 8.53119L6.58682 10.3456L11.0071 5.08444C11.1063 4.9698 11.2466 4.89879 11.3977 4.88675C11.5487 4.87471 11.6985 4.92261 11.8146 5.0201C11.9306 5.11759 12.0036 5.25683 12.0179 5.40772C12.0321 5.55862 11.9864 5.70905 11.8905 5.82651Z"
                          fill="#03B37F"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="center-btn-preview">
                <b-button
                  v-if="isHovering"
                  :class="{
                    zoom: selectedAnime === 'zoom',
                    shake: selectedAnime === 'shake',
                    pulse: selectedAnime === 'pulse',
                  }"
                  @mouseover="isHovering = true"
                  @mouseout="isHovering = false"
                  :style="
                    'width: ' +
                    width +
                    medida +
                    '; height: ' +
                    height +
                    'px; font-size: ' +
                    fontSize +
                    'px; ' +
                    'color: ' +
                    colorTxt +
                    '; background: ' +
                    colorHover +
                    '; border: solid ' +
                    bordaWeightHover +
                    'px ' +
                    colorBorderHover +
                    '; border-radius: ' +
                    bordaRadius +
                    'px ' +
                    activeStyle()
                  "
                  >{{ textBtn }}</b-button
                >

                <b-button
                  class="btn-preview-anime"
                  v-if="!isHovering"
                  @mouseover="isHovering = true"
                  @mouseout="isHovering = false"
                  :style="
                    'width: ' +
                    width +
                    medida +
                    '; height: ' +
                    height +
                    'px; font-size: ' +
                    fontSize +
                    'px; ' +
                    'color: ' +
                    colorTxt +
                    '; background: ' +
                    colorBtn +
                    '; border: solid ' +
                    bordaWeight +
                    'px ' +
                    colorBorder +
                    '; border-radius: ' +
                    bordaRadius +
                    'px; ' +
                    activeStyle()
                  "
                  >{{ textBtn }}</b-button
                >
              </div>
              <div class="center-btn-preview">
                <button type="button" id="new-payment">
                  Escolher outro método de pagamento
                </button>
              </div>
              <div class="center-btn-preview" v-if="checkedNotBuy">
                <a
                  class="mt-2 pointer"
                  :href="linkToNotBuy"
                  target="_blank"
                  :style="
                    'font-size: ' +
                    fontSizeLink +
                    'px; ' +
                    'color: ' +
                    colorTxtLink +
                    ';' +
                    activeStyleLink()
                  "
                  >{{ textBtnLink }}</a
                >
              </div>
            </div>
          </div>
        </span>
        <span>
          <BaseButton
            variant="primary"
            v-if="editId === null"
            class="w100 mb-4"
            @click="saveUpsell()"
            >Salvar Upsell</BaseButton
          >
          <BaseButton
            variant="primary"
            v-else
            class="w100 mb-4"
            @click="updateUpsell()"
            >Atualizar Upsell</BaseButton
          >
          <BaseButton
            variant="link-info"
            :disabled="loading"
            class="w100 mb-4"
            @click="config = false"
          >
            {{ $t('client.text_51') }}
          </BaseButton>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import ProductService from "@/services/resources/ProductService";
import UpsellService from "@/services/resources/UpsellService";
import UpsellMetaService from "@/services/resources/UpsellMetaService";
import _ from "lodash";
import Money from "@/mixins/money";

const serviceUpsell = UpsellService.build();
const serviceMetaUpsell = UpsellMetaService.build();
const serviceProducts = ProductService.build();

export default {
  mixins: [Money],
  components: {},
  data() {
    return {
      editMetas: [],
      editId: null,
      upsellName: "",
      upsells: [],
      product_id: null,
      config: false,
      products: [],
      type: false,
      textBtn: "Meu Botão",
      textBtnLink: "não quero a oferta",
      fontSizeLink: 13,
      typeTextLink: [],
      checkedNotBuy: false,
      colorTxtLink: "#333333",
      linkToNotBuy: "",
      pgnLink: "",
      fontSize: 18,
      nomeProduto: "Nome do Produto",
      valorProduto: "100",
      typeText: [],
      height: 50,
      width: 300,
      mostRange: false,
      isHovering: false,
      colorBorder: "#FF3F4A",
      colorBorderHover: "#FF3F4A",
      bordaWeightHover: 2,
      bordaWeight: 2,
      bordaRadius: 4,
      colorHover: "#333333",
      colorBtn: "#FF3F4A",
      colorTxt: "#ffffff",
      loading: false,
      client: {
        width: 0,
      },
      maxLength: 48,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      load: true,
      selectedAnime: "zoom",
      medida: "px",
      medidas: [
        { text: "Pixel", value: "px" },
        { text: "Porcentagem", value: "%" },
      ],
      optionsAnime: [
        { text: "Zoom", value: "zoom" },
        { text: "Pulse", value: "pulse" },
        { text: "Shake", value: "shake" },
      ],
      advanced: false,
      checkedInject: false,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    baseAdmURL() {
      return this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE;
    },
    username() {
      return this.$store.getters.getProfile.username;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.maxLength = 33;
      } else this.maxLength = 48;
    },
  },
  methods: {
    focusInput() {
      this.mostRange = true;
    },
    changeProduct(product_id) {
      var products = this.productsAll;
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        if (product.id === product_id) {
          this.nomeProduto = product.name;
          this.valorProduto = product.amount;
        }
      }
    },
    makeUpsell() {
      this.config = true;
      this.limpaInputs();
    },
    limpaInputs() {
      this.editMetas = [];
      this.editId = null;
      this.type = null;
      this.checkedInject = false;
      this.advanced = false;
      this.upsellName = "";
      this.pgnLink = "";
      (this.textBtnLink = "não quero a oferta"),
        (this.fontSizeLink = 13),
        (this.typeTextLink = []),
        (this.checkedNotBuy = false),
        (this.colorTxtLink = "#333333"),
        (this.linkToNotBuy = ""),
        (this.textBtn = "Meu Botão");
      this.fontSize = 18;
      this.typeText = [];
      this.height = 50;
      this.medida = "px";
      this.width = 300;
      this.colorBtn = "#FF3F4A";
      this.colorTxt = "#ffffff";
      this.colorBorder = "#FF3F4A";
      this.bordaWeight = 2;
      this.bordaRadius = 4;
      this.colorHover = "#333333";
      this.colorBorderHover = "#FF3F4A";
      this.bordaWeightHover = 2;
      this.selectedAnime = "zoom";
    },
    codeUp(code) {
      var id = code.id;
      var inject = code.inject_html;
      if (inject) {
        inject = 'data-upsell-inject="true"';
      } else {
        inject = "";
      }
      if (
        window.location.host === "victoradm.innovaweb.com.br" ||
        process.env.VUE_APP_API_HOST === "https://victorapi.innovaweb.com.br"
      ) {
        return `<script src="https://testeallan.gpages.com.br/lucas/upsell/upsell.js" id="greennUpsell" ${inject} data-upsell-greenn-id="${id}"><\/script>`;
      } else {
        return `<script src="https://greenn.nyc3.digitaloceanspaces.com/upsell/upsell.js" id="greennUpsell" ${inject} data-upsell-greenn-id="${id}"><\/script>`;
      }
    },
    editMeta(data) {
      serviceUpsell
        .read(data.id + "/metas")
        .then((response) => {
          this.editId = response.id;
          this.product_id = response.product_id;
          this.upsellName = response.title;
          this.changeProduct(response.product_id);
          var configs = response.metas;
          this.editMetas = response.metas;
          for (var indice in configs) {
            let config = configs[indice];
            if (config.upsell_key === "txt_btn") {
              this.textBtn = config.upsell_value;
            } else if (config.upsell_key === "txt_size") {
              this.fontSize = config.upsell_value;
            } else if (config.upsell_key === "txt_style") {
              this.typeText = JSON.parse(config.upsell_value);
            } else if (config.upsell_key === "height") {
              this.height = config.upsell_value;
            } else if (config.upsell_key === "medida") {
              this.medida = config.upsell_value;
            } else if (config.upsell_key === "width") {
              this.width = config.upsell_value;
            } else if (config.upsell_key === "background") {
              this.colorBtn = config.upsell_value;
            } else if (config.upsell_key === "color") {
              this.colorTxt = config.upsell_value;
            } else if (config.upsell_key === "border_color") {
              this.colorBorder = config.upsell_value;
            } else if (config.upsell_key === "border_weight") {
              this.bordaWeight = config.upsell_value;
            } else if (config.upsell_key === "border_radius") {
              this.bordaRadius = config.upsell_value;
            } else if (config.upsell_key === "background_hover") {
              this.colorHover = config.upsell_value;
            } else if (config.upsell_key === "border_color_hover") {
              this.colorBorderHover = config.upsell_value;
            } else if (config.upsell_key === "border_weight_hover") {
              this.bordaWeightHover = config.upsell_value;
            } else if (config.upsell_key === "animation") {
              this.selectedAnime = config.upsell_value;
            } else if (config.upsell_key === "switch_check_not_buy") {
              let result = config.upsell_value;
              if (result === "1") {
                result = true;
              } else {
                result = false;
              }
              this.checkedNotBuy = result;
            } else if (config.upsell_key === "text_btn_not_buy") {
              this.textBtnLink = config.upsell_value;
            } else if (config.upsell_key === "font_size_not_buy") {
              this.fontSizeLink = config.upsell_value;
            } else if (config.upsell_key === "txt_style_not_buy") {
              this.typeTextLink = JSON.parse(config.upsell_value);
            } else if (config.upsell_key === "txt_color_not_buy") {
              this.colorTxtLink = config.upsell_value;
            } else if (config.upsell_key === "link_not_buy") {
              this.linkToNotBuy = config.upsell_value;
            } else if (config.upsell_key === "link_obrigado") {
              this.pgnLink = config.upsell_value;
            } else if (config.upsell_key === "inject_html") {
              let result = config.upsell_value;
              if (result === "1") {
                result = true;
                this.advanced = true;
              } else {
                this.advanced = false;
                result = false;
              }
              this.checkedInject = result;
            }
          }
          this.config = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    copyAddress(code) {
      const el = document.createElement("textarea");
      el.value = code;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast("O codigo foi copiado com sucesso!", {
        title: "Copiado!",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    returnMetas() {
      return [
        {
          key: "inject_html",
          value: this.checkedInject,
        },
        {
          key: "txt_btn",
          value: this.textBtn,
        },
        {
          key: "txt_size",
          value: this.fontSize,
        },
        {
          key: "txt_style",
          value: JSON.stringify(this.typeText),
        },
        {
          key: "height",
          value: this.height,
        },
        {
          key: "medida",
          value: this.medida,
        },
        {
          key: "width",
          value: this.width,
        },
        {
          key: "background",
          value: this.colorBtn,
        },
        {
          key: "color",
          value: this.colorTxt,
        },
        {
          key: "border_color",
          value: this.colorBorder,
        },
        {
          key: "border_weight",
          value: this.bordaWeight,
        },
        {
          key: "border_radius",
          value: this.bordaRadius,
        },
        {
          key: "background_hover",
          value: this.colorHover,
        },
        {
          key: "border_color_hover",
          value: this.colorBorderHover,
        },
        {
          key: "border_weight_hover",
          value: this.bordaWeightHover,
        },
        {
          key: "animation",
          value: this.selectedAnime,
        },
        {
          key: "switch_check_not_buy",
          value: this.checkedNotBuy,
        },
        {
          key: "text_btn_not_buy",
          value: this.textBtnLink,
        },
        {
          key: "font_size_not_buy",
          value: this.fontSizeLink,
        },
        {
          key: "txt_style_not_buy",
          value: JSON.stringify(this.typeTextLink),
        },
        {
          key: "link_not_buy",
          value: this.linkToNotBuy,
        },
        {
          key: "txt_color_not_buy",
          value: this.colorTxtLink,
        },
        {
          key: "link_obrigado",
          value: this.pgnLink,
        },
      ];
    },
    updateUpsell() {
      var metas = this.returnMetas();
      var metasSaved = this.editMetas;
      for (let i = 0; i < metas.length; i++) {
        const meta = metas[i];
        if (meta.key === metasSaved[i].upsell_key) {
          meta.id = metasSaved[i].id;
        }
      }

      let data = {
        id: this.editId + "/meta",
        title: this.upsellName,
        product_id: this.product_id,
        metas: metas,
      };
      serviceUpsell
        .update(data)
        .then((response) => {
          this.fetchUpsells();
          this.config = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveUpsell() {
      let data = {
        title: this.upsellName,
        product_id: this.product_id,
        metas: this.returnMetas(),
      };
      serviceMetaUpsell
        .create(data)
        .then((response) => {
          this.fetchUpsells();
          this.config = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUpsell(id) {
      serviceUpsell
        .destroy(id)
        .then((response) => {
          this.fetchUpsells();
          this.$bvToast.toast("Upsell removido com sucesso!", {
            title: "Upsell",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    activeStyleLink() {
      var style = this.typeTextLink;
      if (style.length === 2) {
        return " font-weight:bold; font-style:italic; ";
      } else if (style.length === 1) {
        if (style[0] === "bold") {
          return " font-weight:bold; ";
        } else if (style[0] === "italic") {
          return " font-style:italic; ";
        }
      } else if (style.length === 0) {
        return "";
      }
    },
    activeStyle() {
      var style = this.typeText;
      if (style.length === 2) {
        return " font-weight:bold; font-style:italic; ";
      } else if (style.length === 1) {
        if (style[0] === "bold") {
          return " font-weight:bold; ";
        } else if (style[0] === "italic") {
          return " font-style:italic; ";
        }
      } else if (style.length === 0) {
        return "";
      }
    },
    cutText(text) {
      if (text) {
        return text.length > this.maxLength
          ? text.substr(0, this.maxLength) + "..."
          : text;
      } else return text;
    },
    changeWidth() {
      if (this.medida === "%") {
        this.width = 100;
      }
    },
    toggleTab(type) {
      this.type = type;
    },
    fetchUpsells() {
      serviceUpsell
        .search()
        .then((response) => {
          this.upsells = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchProducts() {
      this.products = [{ value: null, text: "Selecione um produto" }];

      let data = {
        list: true,
      };

      serviceProducts
        .search(data)
        .then((response) => {
          this.productsAll = response;
          response.forEach((element, index) => {
            this.products.push({
              value: element.id,
              text: element.name,
            });
          });
          this.fetchUpsells();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    this.fetchProducts();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.advanced {
  width: 100%;
  .ml-custom {
    margin-left: 35px;
  }
  .info-checkbox {
    line-height: 0.5 !important;
  }
  .btn-p {
    text-align: right;
    cursor: pointer;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    color: #737380;
    padding: 10px 0;
    line-height: 20px;
  }
}
.gridGerador {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  .preview-btn {
    padding: 40px 15px;
    margin: 55px 40px;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    border: 1px solid #ededf0;
    border-radius: 5px;
    display: block !important;
  }
}
</style>

<style lang="scss">
.all-config {
  .btn-anime {
    gap: 20px;
    border: none;
    background: none;
    cursor: pointer;
    label {
      border-radius: 3px !important;
      background: rgba(0, 21, 255, 0.1);
      border: none !important;
      height: 40px !important;
      width: 100px !important;
      padding-top: 10px !important;
      span {
        color: #0015ff !important;
      }
    }
    label.active {
      border-radius: 3px !important;
      background: #2133d2 !important;
      border: none !important;
      height: 40px !important;
      width: 100px !important;
      padding-top: 10px !important;
      span {
        color: white !important;
      }
    }
  }
  .config {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    padding: 17px 20px;
    margin-bottom: 10px !important;
    cursor: pointer;
  }

  .active-lab {
    height: auto;
  }

  svg.arrow {
    position: fixed;
    top: 7px;
    right: 0;
  }

  input[type="range"]::-webkit-slider-thumb {
    background: #8c8a97;
  }

  .not-border {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .not-border-color {
    border: none !important;
    margin-left: -18px !important;
    padding-top: 0px !important;
    padding-bottom: 25px !important;
  }

  .bold {
    font-weight: bolder;
  }

  .check {
    margin: 12px 38px 12px 26px !important;
  }

  .italic {
    font-style: italic;
  }

  .dimension {
    margin-top: 10px;
    gap: 20px;
  }
  .top-label {
    height: 13px;
  }
}

#product-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 7px;
}

.body {
  margin-top: 20px;
  margin-bottom: 30px;
}

.custom-input {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  height: 50px;
  pointer-events: none;
}

.body input {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: #333333;
}

.custom-input input {
  height: 100% !important;
  width: 100%;
  padding-left: 15px;
  border: none !important;
  margin: 10px;
  font-family: Montserrat;
}

.custom-input .icon {
  margin-left: 10px;
}

.custom-input .icon-ok {
  margin-right: 10px;
}

.center-btn-preview {
  display: flex;
  justify-content: center;
}

.custom-input input:focus-visible {
  outline: none !important;
}

.boleto-preview {
  border: none !important;
  height: 36px !important;
}

.link {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 5px;
  width: 100%;
  text-decoration: none !important;
}

.link:hover {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 5px;
  width: 100%;
  text-decoration: none !important;
}

.copy-link {
  width: 15px;
  /* margin-left: 20px; */
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}
.copy-link:hover {
  filter: invert(0);
}

h6 {
  font-weight: 600;
  color: #009488;
}
.outros {
  padding-top: 30px;
  border-top: 1px solid #ededf079;
}
.outros-links {
  color: #333;
  font-size: 16px;
}
.wrapper {
  margin-top: -20px !important;
  z-index: 2;
}
.outro-item {
  margin-bottom: 7px;
}
.campanha_nome {
  margin: 0;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
.sub-titulo {
  font-weight: 600;
  color: #81858e;
  font-size: 14px;
}
.titulo-link {
  font-weight: 600;
  font-size: 14px;
}
.container-item {
  padding: 50px !important;
}

.sub-titulo {
  font-weight: 600;
  color: #81858e;
  font-size: 14px;
}

.pointer-label {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }
  .wrapper {
    padding: 30px 20px !important;
  }
  .container-item {
    padding: 30px !important;
    margin-bottom: 20px;
  }
  .tabs {
    margin: 40px 0 !important;
  }
  h6 {
    font-size: 15px;
  }
  .btn-adicionar {
    width: 100%;
    margin-top: -30px;
    margin-bottom: 10px;
  }

  .link-extra + .link-extra {
    margin-top: 15px;
  }
}
.p-editado {
  font-size: 14px;
  color: #81858e;
}

.w100 {
  width: 100%;
}
.pointer {
  cursor: pointer;
  text-decoration: none !important;
}

.ml-switch {
  margin-left: 35px;
}

#new-payment {
  width: 100%;
  border-radius: 5px;
  background: transparent;
  color: #333333;
  border: none;
  height: 50px;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 13px;
  line-height: 16px;
  margin-top: 3px;
}

.zoom {
  transition: all 0.3s;
  transform: scale(1.1);
}

.shake {
  animation: shake 0.82s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.pulse {
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@keyframes pulse {
  0% {
    transform: scale(0.97);
    box-shadow: 0 0 0 0 var(--maincolor);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.97);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.white-space {
  white-space: nowrap;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
