<template>
  <svg
    width="215"
    height="263"
    viewBox="0 0 215 263"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M210 0.661133H5.87714C3.11571 0.661133 0.877136 2.8997 0.877136 5.66113V257.28C0.877136 260.041 3.11571 262.28 5.87713 262.28H210C212.761 262.28 215 260.041 215 257.28V5.66113C215 2.89971 212.761 0.661133 210 0.661133Z"
      fill="white"
      :style="'fill: ' + setTheme(item.metas.theme) + ';'"
    />
    <path
      d="M5.87714 1.16113H210C212.485 1.16113 214.5 3.17585 214.5 5.66113V257.28C214.5 259.765 212.485 261.78 210 261.78H5.87713C3.39185 261.78 1.37714 259.765 1.37714 257.28V5.66113C1.37714 3.17585 3.39185 1.16113 5.87714 1.16113Z"
      stroke="#EDEDF0"
      stroke-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M0.877136 26.2678H215V26.9816H0.877136V26.2678Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 12.6991C15.152 11.5166 16.1106 10.5579 17.2932 10.5579H41.8817C43.0643 10.5579 44.0229 11.5166 44.0229 12.6991V15.5541C44.0229 16.7367 43.0643 17.6953 41.8817 17.6953H17.2932C16.1106 17.6953 15.152 16.7367 15.152 15.5541V12.6991Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M22.2894 44.9918C22.2894 46.9627 20.6917 48.5605 18.7207 48.5605C16.7498 48.5605 15.152 46.9627 15.152 44.9918C15.152 43.0208 16.7498 41.4231 18.7207 41.4231C20.6917 41.4231 22.2894 43.0208 22.2894 44.9918Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M33.1416 44.2782C33.1416 43.0956 34.1002 42.137 35.2828 42.137H94.3775C95.5601 42.137 96.5187 43.0956 96.5187 44.2782V45.7057C96.5187 46.8882 95.5601 47.8469 94.3775 47.8469H35.2828C34.1002 47.8469 33.1416 46.8882 33.1416 45.7057V44.2782Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M22.2894 134.325C22.2894 136.296 20.6917 137.894 18.7207 137.894C16.7498 137.894 15.152 136.296 15.152 134.325C15.152 132.354 16.7498 130.756 18.7207 130.756C20.6917 130.756 22.2894 132.354 22.2894 134.325Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M33.1416 133.611C33.1416 132.429 34.1002 131.47 35.2828 131.47H94.3775C95.5601 131.47 96.5187 132.429 96.5187 133.611V135.039C96.5187 136.222 95.5601 137.18 94.3775 137.18H35.2828C34.1002 137.18 33.1416 136.222 33.1416 135.039V133.611Z"
      fill="#EDEDF0"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
      fill-opacity="0.5"
    />
    <path
      d="M15.152 65.66C15.152 64.4774 16.1106 63.5188 17.2932 63.5188H61.2947C62.4773 63.5188 63.4359 64.4774 63.4359 65.66V67.0875C63.4359 68.27 62.4773 69.2287 61.2947 69.2287H17.2932C16.1106 69.2287 15.152 68.27 15.152 67.0875V65.66Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 169.974C15.152 168.792 16.1106 167.833 17.2932 167.833H61.2947C62.4773 167.833 63.4359 168.792 63.4359 169.974V171.402C63.4359 172.584 62.4773 173.543 61.2947 173.543H17.2932C16.1106 173.543 15.152 172.584 15.152 171.402V169.974Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 151.079C15.152 149.897 16.1106 148.938 17.2932 148.938H48.6979C49.8805 148.938 50.8391 149.897 50.8391 151.079V153.934C50.8391 155.116 49.8805 156.076 48.6979 156.076H17.2932C16.1106 156.076 15.152 155.116 15.152 153.934V151.079Z"
      fill="#009488"
      fill-opacity="0.2"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48.6979 149.295H17.2932C16.3078 149.295 15.5089 150.094 15.5089 151.079V153.934C15.5089 154.919 16.3078 155.719 17.2932 155.719H48.6979C49.6834 155.719 50.4823 154.919 50.4823 153.934V151.079C50.4823 150.094 49.6834 149.295 48.6979 149.295ZM17.2932 148.938C16.1106 148.938 15.152 149.897 15.152 151.079V153.934C15.152 155.116 16.1106 156.076 17.2932 156.076H48.6979C49.8805 156.076 50.8391 155.116 50.8391 153.934V151.079C50.8391 149.897 49.8805 148.938 48.6979 148.938H17.2932Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M55.4783 151.079C55.4783 149.897 56.4369 148.938 57.6195 148.938H89.0244C90.207 148.938 91.1656 149.897 91.1656 151.079V153.934C91.1656 155.116 90.207 156.076 89.0244 156.076H57.6195C56.4369 156.076 55.4783 155.116 55.4783 153.934V151.079Z"
      fill="#009488"
      fill-opacity="0.2"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M89.0244 149.295H57.6195C56.634 149.295 55.8352 150.094 55.8352 151.079V153.934C55.8352 154.919 56.634 155.719 57.6195 155.719H89.0244C90.0093 155.719 90.8087 154.919 90.8087 153.934V151.079C90.8087 150.094 90.0093 149.295 89.0244 149.295ZM57.6195 148.938C56.4369 148.938 55.4783 149.897 55.4783 151.079V153.934C55.4783 155.116 56.4369 156.076 57.6195 156.076H89.0244C90.207 156.076 91.1656 155.116 91.1656 153.934V151.079C91.1656 149.897 90.207 148.938 89.0244 148.938H57.6195Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M95.8049 151.079C95.8049 149.897 96.7635 148.938 97.9462 148.938H129.351C130.533 148.938 131.492 149.897 131.492 151.079V153.934C131.492 155.116 130.533 156.076 129.351 156.076H97.9462C96.7635 156.076 95.8049 155.116 95.8049 153.934V151.079Z"
      fill="#009488"
      fill-opacity="0.2"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M129.351 149.295H97.9462C96.9605 149.295 96.1618 150.094 96.1618 151.079V153.934C96.1618 154.919 96.9605 155.719 97.9462 155.719H129.351C130.336 155.719 131.135 154.919 131.135 153.934V151.079C131.135 150.094 130.336 149.295 129.351 149.295ZM97.9462 148.938C96.7635 148.938 95.8049 149.897 95.8049 151.079V153.934C95.8049 155.116 96.7635 156.076 97.9462 156.076H129.351C130.533 156.076 131.492 155.116 131.492 153.934V151.079C131.492 149.897 130.533 148.938 129.351 148.938H97.9462Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M136.131 151.079C136.131 149.897 137.089 148.938 138.272 148.938H169.677C170.859 148.938 171.818 149.897 171.818 151.079V153.934C171.818 155.116 170.859 156.076 169.677 156.076H138.272C137.089 156.076 136.131 155.116 136.131 153.934V151.079Z"
      fill="#009488"
      fill-opacity="0.2"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M169.677 149.295H138.272C137.287 149.295 136.488 150.094 136.488 151.079V153.934C136.488 154.919 137.287 155.719 138.272 155.719H169.677C170.662 155.719 171.461 154.919 171.461 153.934V151.079C171.461 150.094 170.662 149.295 169.677 149.295ZM138.272 148.938C137.089 148.938 136.131 149.897 136.131 151.079V153.934C136.131 155.116 137.089 156.076 138.272 156.076H169.677C170.859 156.076 171.818 155.116 171.818 153.934V151.079C171.818 149.897 170.859 148.938 169.677 148.938H138.272Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M15.152 78.8737C15.152 77.691 16.1106 76.7324 17.2932 76.7324H80.1076C81.2903 76.7324 82.2488 77.691 82.2488 78.8737V80.3011C82.2488 81.4838 81.2903 82.4424 80.1076 82.4424H17.2932C16.1106 82.4424 15.152 81.4838 15.152 80.3011V78.8737Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 183.188C15.152 182.005 16.1106 181.047 17.2932 181.047H80.1076C81.2903 181.047 82.2488 182.005 82.2488 183.188V184.615C82.2488 185.798 81.2903 186.757 80.1076 186.757H17.2932C16.1106 186.757 15.152 185.798 15.152 184.615V183.188Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 92.087C15.152 90.9044 16.1106 89.9458 17.2932 89.9458H98.6599C99.8426 89.9458 100.801 90.9044 100.801 92.087V93.5145C100.801 94.6972 99.8426 95.6557 98.6599 95.6557H17.2932C16.1106 95.6557 15.152 94.6972 15.152 93.5145V92.087Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 196.401C15.152 195.219 16.1106 194.26 17.2932 194.26H98.6599C99.8426 194.26 100.801 195.219 100.801 196.401V197.829C100.801 199.012 99.8426 199.97 98.6599 199.97H17.2932C16.1106 199.97 15.152 199.012 15.152 197.829V196.401Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 105.301C15.152 104.118 16.1106 103.159 17.2932 103.159H72.129C73.3113 103.159 74.2706 104.118 74.2706 105.301V106.728C74.2706 107.911 73.3113 108.869 72.129 108.869H17.2932C16.1106 108.869 15.152 107.911 15.152 106.728V105.301Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 209.615C15.152 208.432 16.1106 207.474 17.2932 207.474H72.129C73.3113 207.474 74.2706 208.432 74.2706 209.615V211.042C74.2706 212.225 73.3113 213.184 72.129 213.184H17.2932C16.1106 213.184 15.152 212.225 15.152 211.042V209.615Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M174.833 14.1266C174.833 13.1412 175.632 12.3423 176.618 12.3423H198.941C199.926 12.3423 200.725 13.1412 200.725 14.1266C200.725 15.1121 199.926 15.911 198.941 15.911H176.618C175.632 15.911 174.833 15.1121 174.833 14.1266Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M172.175 14.1266C172.175 16.0976 170.577 17.6953 168.607 17.6953C166.636 17.6953 165.038 16.0976 165.038 14.1266C165.038 12.1556 166.636 10.5579 168.607 10.5579C170.577 10.5579 172.175 12.1556 172.175 14.1266Z"
      fill="#00AF7B"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M165.286 12.8479L171.958 12.9367L172.183 14.179L171.958 15.4053H165.286L165.03 14.1049L165.286 12.8479Z"
      fill="#FFDA44"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M198.584 42.4938H145.053C144.067 42.4938 143.268 43.2927 143.268 44.2782V83.847C143.268 84.8327 144.067 85.6314 145.053 85.6314H198.584C199.57 85.6314 200.368 84.8327 200.368 83.847V44.2782C200.368 43.2927 199.57 42.4938 198.584 42.4938ZM145.053 42.137C143.87 42.137 142.911 43.0956 142.911 44.2782V83.847C142.911 85.0297 143.87 85.9883 145.053 85.9883H198.584C199.767 85.9883 200.725 85.0297 200.725 83.847V44.2782C200.725 43.0956 199.767 42.137 198.584 42.137H145.053Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M142.911 44.2782C142.911 43.0956 143.87 42.137 145.053 42.137H198.584C199.767 42.137 200.725 43.0956 200.725 44.2782V52.776H142.911V44.2782Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M148.117 47.4564C148.117 46.865 148.596 46.3857 149.188 46.3857H180.888C181.48 46.3857 181.959 46.865 181.959 47.4564C181.959 48.0476 181.48 48.527 180.888 48.527H149.188C148.596 48.527 148.117 48.0476 148.117 47.4564Z"
      fill="white"
      fill-opacity="0.5"
    />
    <path
      d="M171.819 60.6321C171.819 60.0409 172.297 59.5615 172.889 59.5615H193.54C194.131 59.5615 194.611 60.0409 194.611 60.6321C194.611 61.2234 194.131 61.7028 193.54 61.7028H172.889C172.297 61.7028 171.819 61.2234 171.819 60.6321Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M172.183 66.3421C172.183 65.7508 172.663 65.2715 173.254 65.2715H189.707C190.298 65.2715 190.778 65.7508 190.778 66.3421C190.778 66.9334 190.298 67.4127 189.707 67.4127H173.254C172.663 67.4127 172.183 66.9334 172.183 66.3421Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M148.117 77.4141C148.117 76.8231 148.596 76.3435 149.188 76.3435H180.888C181.48 76.3435 181.959 76.8231 181.959 77.4141C181.959 78.0058 181.48 78.4847 180.888 78.4847H149.188C148.596 78.4847 148.117 78.0058 148.117 77.4141Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M114.004 169.465C114.004 168.283 114.963 167.324 116.145 167.324H169.677C170.859 167.324 171.818 168.283 171.818 169.465V209.035C171.818 210.217 170.859 211.176 169.677 211.176H116.145C114.963 211.176 114.004 210.217 114.004 209.035V169.465Z"
      fill="#009488"
      fill-opacity="0.2"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M152.237 176.302C152.237 175.513 152.876 174.874 153.664 174.874H166.201C166.989 174.874 167.629 175.513 167.629 176.302C167.629 177.089 166.989 177.729 166.201 177.729H153.664C152.876 177.729 152.237 177.089 152.237 176.302Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M117.773 188.575C117.773 188.181 118.093 187.862 118.487 187.862H131.621C132.015 187.862 132.334 188.181 132.334 188.575C132.334 188.97 132.015 189.289 131.621 189.289H118.487C118.093 189.289 117.773 188.97 117.773 188.575Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M117.773 200.906C117.773 200.512 118.093 200.192 118.487 200.192H131.621C132.015 200.192 132.334 200.512 132.334 200.906C132.334 201.3 132.015 201.62 131.621 201.62H118.487C118.093 201.62 117.773 201.3 117.773 200.906Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M153.068 204.98C153.068 204.586 153.388 204.266 153.782 204.266H166.915C167.309 204.266 167.629 204.586 167.629 204.98C167.629 205.374 167.309 205.694 166.915 205.694H153.782C153.388 205.694 153.068 205.374 153.068 204.98Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M117.773 192.649C117.773 192.255 118.093 191.936 118.487 191.936H156.55C156.945 191.936 157.264 192.255 157.264 192.649C157.264 193.044 156.945 193.363 156.55 193.363H118.487C118.093 193.363 117.773 193.044 117.773 192.649Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M117.773 204.98C117.773 204.586 118.093 204.266 118.487 204.266H142.483C142.878 204.266 143.197 204.586 143.197 204.98C143.197 205.374 142.878 205.694 142.483 205.694H118.487C118.093 205.694 117.773 205.374 117.773 204.98Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M148.117 58.7965C148.117 57.614 149.076 56.6553 150.258 56.6553H164.189C165.372 56.6553 166.331 57.6139 166.331 58.7965V69.0486C166.331 70.2312 165.372 71.1899 164.189 71.1899H150.258C149.076 71.1899 148.117 70.2312 148.117 69.0486V58.7965Z"
      fill="#EDEDF0"
      fill-opacity="0.5"
      :style="'fill-opacity: ' + setThemeInputs(item.metas.theme) + ';'"
    />
    <path
      d="M15.152 236.042C15.152 234.859 16.1106 233.901 17.2932 233.901H169.677C170.859 233.901 171.818 234.859 171.818 236.042V246.034C171.818 247.217 170.859 248.175 169.677 248.175H17.2932C16.1106 248.175 15.152 247.217 15.152 246.034V236.042Z"
      fill="#009488"
      :style="'fill: ' + item.metas.theme_color + ';'"
    />
    <path
      d="M62.0604 241.038C62.0604 240.446 62.5397 239.967 63.131 239.967H123.839C124.43 239.967 124.91 240.446 124.91 241.038C124.91 241.629 124.43 242.109 123.839 242.109H63.131C62.5397 242.109 62.0604 241.629 62.0604 241.038Z"
      fill="white"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    setTheme(data) {
      if (data === "dark") {
        return "#17191F";
      }
    },
    setThemeInputs(data){
        if (data === "dark") {
            return "0.3";
        }    
    }
  },
};
</script>

<style>
</style>        
